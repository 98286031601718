import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Preparació Entrevistes per Mossos d'Esquadra (1:30 h - Custom)</Title>
                <Text></Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
